export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    projectWithDetail: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    projectBarWidth() {
      if (!this.project.has_target_money || this.project.percent > 100) {
        return '100%';
      }
      return `${this.project.percent}%`;
    },
  },
};
