<template>
  <div>
    <div class="popular-base">
      <project-new />
    </div>
  </div>
</template>

<script>
import ProjectNew from '@/pc/components/project/ProjectNew';

export default {
  name: 'ProjectNewView',
  components: {
    ProjectNew,
  },
};
</script>

<style scoped>
.popular-base {
  background: #f5f5f5;
  display: block;
  height: auto;
  overflow: hidden;
  padding-top: 15px;
  width: 100%;
}
</style>
