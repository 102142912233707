export default {
  props: {
    titleLinkText: {
      type: String,
      default: 'すべて見る',
    },
    titleLinkHref: {
      type: String,
      default: null,
    },
    titleLinkTarget: {
      type: String,
      default: null,
    },
  },
  computed: {
    hasLink() {
      return this.titleLinkHref !== null;
    },
  },
};
