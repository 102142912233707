export default {
  data() {
    return {
      projects: [],
      projectsPagination: {},
      isLoading: false,
    };
  },
  created() {
    this.loadProjects();
  },
  methods: {
    async loadProjects() {
      this.isLoading = true;
      try {
        const response = await this.fetchProjects();
        this.projects = this.manipulateProjects(response.data.projects);
        this.projectsPagination = response.data.pagination;
      } finally {
        this.isLoading = false;
      }
    },
    setProjects(projects) {
      this.projects = projects;
    },
    concatProjects(projects) {
      this.projects = [...this.projects, ...projects];
    },
    /**
     * プロジェクトリストを加工（フィルタリング等）したい場合はコンポーネント側でオーバーライドする
     *
     * @param projects 加工前のプロジェクトリスト
     * @returns array 加工後のプロジェクトリスト
     */
    manipulateProjects(projects) {
      return projects;
    },
  },
  computed: {
    hasProjects() {
      return this.projects.length > 0;
    },
  },
};
