<template>
  <svg class="MIcon">
    <use :xlink:href="href" :href="href"></use>
  </svg>
</template>

<script>
export default {
  name: 'MIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    hrefPrefix: {
      type: String,
      default() {
        return '#icon-';
      },
    },
  },
  computed: {
    href() {
      return `${this.hrefPrefix}${this.name}`;
    },
  },
};
</script>
