import { createApp } from 'vue';
import filters from '@/modules/filters';
import MCdn from '@/components/MCdn';
import ProjectNewView from '@/pc/components/project/ProjectNewView';

const app = createApp(ProjectNewView);

app.config.globalProperties.$filters = filters;
app.component('MCdn', MCdn);
app.mount('#vue-project-new');
