export default {
  methods: {
    eventCategory(device, page) {
      return JSON.stringify({
        device,
        page,
      });
    },
    eventLabel(module, parts = '', position = null) {
      const label = {
        module,
        parts,
      };

      if (position != null) {
        label.position = position;
      }

      return JSON.stringify(label);
    },
    pushGtmClickEvent(eventCategory, eventLabel) {
      if (!eventCategory) {
        return;
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'click_tracking',
        event_category: eventCategory,
        event_action: 'click',
        event_label: eventLabel,
      });
    },
  },
};
