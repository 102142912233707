let gtag;

export default function useGaEvent() {
  // グローバルな gtag 関数を取得
  if (!gtag) gtag = window.gtag || function () {};

  /**
   * GAにクリックイベントを送信します。
   * @param {string} id クリックされた要素をページ内で一意に識別できるような値を指定します。可能な限り短くしアンダースコアで連結してください。集計時のソートや前方一致検索のためなるべく一語目にコンテンツの種類（名詞）を指定します。例: "project", "slideshow", "navigation_new"
   * @param {number | undefined} [index=undefined] 任意値。クリックされた要素のインデックスを0から始まる整数値で指定します。例: 0, 1, 2, 3, ...
   */
  const sendClickEvent = (id, index = undefined) => {
    gtag('event', 'click_element', { element_id: id, element_index: index });
  };

  /**
   * GAにプロジェクトや記事などのコンテンツ一覧閲覧イベントを送信します。
   * @param {string} id コンテンツリストのID
   * @param {string} name コンテンツリストの名前
   */
  const sendViewContentListEvent = (id, name) => {
    gtag('event', 'view_content_list', {
      content_list_id: id,
      content_list_name: name,
    });
  };

  /**
   * GAに select_content 推奨イベントを送信します。ユーザーが特定のタイプのコンテンツを選択したことを示します。
   * @param {string | number} id 選択されたコンテンツの識別子。例: "C_12345"
   * @param {string} type 選択されたコンテンツの種類。例: "product"
   */
  const sendSelectContentEvent = (id, type) => {
    const obj = {};
    if (id) obj.content_id = String(id);
    if (type) obj.content_type = type;
    gtag('event', 'select_content', obj);
  };

  /**
   * GAに「気になるリストに追加」イベントを送信します。
   * @param {number} projectId 追加されたプロジェクトのID
   */
  const sendAddToLikeListEvent = projectId => {
    gtag('event', 'add_to_like_list', { project_id: projectId });
  };

  /**
   * GAに「Myタグ登録」イベントを送信します。
   * @param {number} tagId 追加されたタグのID
   */
  const sendAddToTagListEvent = tagId => {
    gtag('event', 'add_to_tag_list', { tag_id: tagId });
  };

  return {
    sendAddToLikeListEvent,
    sendAddToTagListEvent,
    sendSelectContentEvent,
    sendClickEvent,
    sendViewContentListEvent,
  };
}
