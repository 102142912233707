export default {
  props: {
    linkHref: {
      type: String,
      required: true,
    },
    linkTarget: {
      type: String,
      default: null,
    },
  },
};
