<template>
  <img :alt="alt" class="MCdn" loading="lazy" :src="paramsAddedSrc" />
</template>
<script>
import qs from 'qs';
import _ from 'lodash';

export default {
  name: 'MCdn',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    cdnWidth: {
      type: Number,
    },
    cdnHeight: {
      type: Number,
    },
    cdnQuality: {
      type: Number,
      default: 70,
    },
  },
  computed: {
    paramsAddedSrc() {
      if (!this.src) {
        return '';
      }

      const [, queryString] = this.src.split('?', 2);
      const params = {};
      if (!_.has(qs.parse(queryString), 'quality')) {
        params.quality = this.cdnQuality;
      }
      if (!_.has(qs.parse(queryString), 'width')) {
        params.width = this.cdnWidth;
      }
      if (!_.has(qs.parse(queryString), 'height')) {
        params.height = this.cdnHeight;
      }

      if (!Object.keys(params).length) {
        return this.src;
      }
      return `${this.src}&${qs.stringify(params)}`;
    },
  },
};
</script>
