<template>
  <div class="ScrollContainer">
    <button v-if="!isIE" class="scroll-button left-button" @click="scrollLeft">
      <svg class="arrow">
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#icon-arrow-left"
        />
      </svg>
    </button>
    <button
      v-if="!isIE"
      class="scroll-button right-button"
      @click="scrollRight"
    >
      <svg class="arrow">
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#icon-arrow-right"
        />
      </svg>
    </button>
    <div ref="scrollRef" class="scroll-target" :class="{ 'is-ie': isIE }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollContainer',
  props: {
    scrollOffset: {
      type: Number,
      default() {
        return 948;
      },
    },
    /** trueが渡されるとスクロールを左端へ戻す */
    needResetScroll: {
      type: Boolean,
    },
    /** スクロールがリセットされた後に呼ばれる関数 */
    afterResetScroll: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      ua: null,
    };
  },
  computed: {
    isIE() {
      return this.ua && this.ua.isIE();
    },
  },
  watch: {
    /** needResetScrollにtrueが渡された時にスクロールを左端へ戻すためのウォッチャー */
    needResetScroll() {
      if (!this.$refs.scrollRef || !this.needResetScroll) return;

      const { scrollRef } = this.$refs;
      scrollRef.scrollTo({
        left: this.scrollOffset * -1,
        behavior: 'smooth',
      });

      if (this.afterResetScroll) this.afterResetScroll();
    },
  },
  methods: {
    scrollLeft() {
      if (!this.$refs.scrollRef) {
        return;
      }
      const { scrollRef } = this.$refs;
      scrollRef.scrollTo({
        left: scrollRef.scrollLeft - this.scrollOffset,
        behavior: 'smooth',
      });
    },
    scrollRight() {
      if (!this.$refs.scrollRef) {
        return;
      }
      const { scrollRef } = this.$refs;
      scrollRef.scrollTo({
        left: scrollRef.scrollLeft + this.scrollOffset,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.ScrollContainer {
  position: relative;
}

.scroll-button {
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  cursor: pointer;
  height: 34px;
  outline: 0;
  position: absolute;
  top: calc(50% - 14px);
  transform: translateY(-50%);
  width: 34px;
}

.scroll-button:hover {
  opacity: 0.8;
}

.left-button {
  left: -53px;
  padding: 0 8px 0 6px;
}

.right-button {
  padding: 0 6px 0 8px;
  right: -53px;
}

.scroll-target {
  -ms-overflow-style: none;
  overflow-x: auto;
  scrollbar-width: none;
}

.scroll-target::-webkit-scrollbar {
  display: none;
}

.is-ie.scroll-target {
  -ms-overflow-style: auto;
}

.arrow {
  fill: #333;
  height: 10px;
  width: 10px;
}
</style>
