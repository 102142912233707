import qs from 'qs';

/**
 * パラメータ付きのURLを構築する
 *
 * @param {string} path urlのパス。ホスト名は client のオプション、envで扱うので、それ以降の部分。
 * @param {object|array} params GETパラメータ。クエリパラメータ。クエリストリング化したいオブジェクトなど。
 */
/* eslint-disable import/prefer-default-export */
export const buildUrl = (path, params = {}) =>
  [path, qs.stringify(params)].filter(_ => _.toString() !== '').join('?');
