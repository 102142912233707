import labels from '@/consts/ja/labels';
import moment from 'moment';
import _ from 'lodash';

export const formatMoney = (amount, symbol = '') => {
  // eslint-disable-next-line no-restricted-globals
  if (!amount || isNaN(amount)) {
    return '0';
  }

  if (typeof amount === 'string') {
    return amount;
  }

  const formatter = Intl.NumberFormat();

  return `${symbol}${formatter.format(amount)}`;
};

export const supportLabel = isGovernment =>
  isGovernment ? labels.donation : labels.support;

export const taxLabel = isGovernment => {
  if (isGovernment) {
    return '';
  }
  return `(${labels.taxIncluded})`;
};

const detectDateFormat = dateString => {
  if (dateString.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
    return 'YYYY-MM-DD';
  }
  if (dateString.match(/^[0-9]{14}$/)) {
    return 'YYYYMMDDHHmmss';
  }
  return null;
};

export const formatPaymentTerm = paymentTerm => {
  if (!paymentTerm) {
    return '';
  }

  return moment(paymentTerm, detectDateFormat(paymentTerm)).format(
    'YYYY年MM月DD日',
  );
};

export const formatDeliveryDate = (deliveryDate, locale = 'jp') => {
  if (!deliveryDate) return '';

  return moment(deliveryDate, 'YYYY-MM-DD').format(
    locale === 'en' ? 'YYYY/MM' : 'YYYY年MM月',
  );
};

export const truncateText = (text, length = 40) =>
  _.truncate(text, { length: length + 3 });
