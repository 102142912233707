export default {
  props: {
    /** @todo: `clickTrackingCategory` と `clickTrackingLabel` からの移行完了後は `required` にしたい。 */
    clickElementElementId: {
      type: String,
      required: false,
      default: '',
    },
    clickElementElementIndex: {
      type: Number,
      required: false,
      default: undefined,
    },
    /** @deprecated 代わりに `clickElementElementId` と `clickElementElementIndex` を使用してください。 */
    clickTrackingCategory: {
      type: String,
      default: '',
      // required: true, // ほんとは必須にしたいけど、まずはオプションで
    },
    /** @deprecated 代わりに `clickElementElementId` と `clickElementElementIndex` を使用してください。 */
    clickTrackingLabel: {
      type: String,
      default: '',
      // required: true, // ほんとは必須にしたいけど、まずはオプションで
    },
  },
};
