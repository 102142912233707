import client, { createClient } from '@/modules/api/createClient';
import { buildUrl } from '@/modules/api/buildUrl';
import { SortKeyCollectedMoney } from '@/consts/discover';

const fetchProjects = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = {};

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchPickup = params => {
  // 上書き可
  const defaultParams = { sort: SortKeyCollectedMoney };
  // 上書き不可
  const forceParams = { type: 'pickup' };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchStorePickup = params => {
  // 上書き可
  const defaultParams = { sort: SortKeyCollectedMoney };
  // 上書き不可
  const forceParams = { type: 'store-pickup' };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchNew = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = { type: 'new' };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchComingSoon = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = {};

  const url = buildUrl('/v2/projects/coming-soons', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchStore = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = { type: 'store' };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchGovernment = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = { type: 'government' };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

/**
 * 直近閲覧したプロジェクトの一覧
 *
 * @param {string} projectIds 配列じゃないので注意
 * @param {object} [params]
 */
const fetchWatched = (projectIds, params) => {
  // 上書き可
  const defaultParams = { per_page: 5 };
  // 上書き不可
  const forceParams = { ids: projectIds };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchEndingSoon = params => {
  // 上書き可
  const defaultParams = { per_page: 5 };
  // 上書き不可
  const forceParams = { type: 'ending-soon' };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchMostFunded = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = { type: 'most-funded' };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchProjectsByTagIds = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = {};

  const { tag_ids: tagIds } = params;

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    // 配列を受け取った場合はカンマ区切りに変換
    tag_ids: Array.isArray(tagIds) ? tagIds.join() : tagIds,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchProjectsByLocationIds = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = {};

  const { location_ids: locationIds } = params;

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    // 配列を受け取った場合はカンマ区切りに変換
    location_ids: Array.isArray(locationIds) ? locationIds.join() : locationIds,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchProjectsByCategoryCode = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = {};

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchProjectsByFilter = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const type = 'filter-with-store';
  const forceParams = { type };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchSelected = params => {
  // 上書き可
  const defaultParams = {};
  // 上書き不可
  const forceParams = { type: 'selected-creator' };

  const url = buildUrl('/v2/projects', {
    ...defaultParams,
    ...params,
    ...forceParams,
  });
  return createClient().get(url);
};

const fetchProjectById = projectId =>
  client.makuakeApiClient.get(`/v2/projects/${projectId}`);

const fetchGlobalProjectById = projectId =>
  client.makuakeApiClient.get(`/v2/projects/${projectId}/globals`);

const fetchProjectTags = projectId =>
  client.makuakeApiClient.get(`/v2/projects/${projectId}/tags`);

export default {
  fetchProjects,
  fetchPickup,
  fetchStorePickup,
  fetchNew,
  fetchComingSoon,
  fetchStore,
  fetchGovernment,
  fetchWatched,
  fetchEndingSoon,
  fetchMostFunded,
  fetchProjectsByTagIds,
  fetchProjectsByLocationIds,
  fetchProjectsByCategoryCode,
  fetchProjectsByFilter,
  fetchSelected,
  fetchProjectById,
  fetchGlobalProjectById,
  fetchProjectTags,
};
