<template>
  <a
    :href="linkHref"
    :target="linkTarget"
    class="TitleLink"
    :data-click-tracking-category="clickTrackingCategory"
    :data-click-tracking-label="clickTrackingLabel"
    @click="handleClick"
  >
    <slot>すべて見る</slot>
    <svg class="arrow">
      <use
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xlink:href="#icon-arrow-right"
      />
    </svg>
  </a>
</template>

<script>
import useGaEvent from '@/composables/common/gaEvent';
import clickTrackingDefinition from '@/mixins/clickTrackingDefinition';
import titleLinkDefinition from '@/mixins/titleLinkDefinition';

export default {
  name: 'TitleLink',
  mixins: [titleLinkDefinition, clickTrackingDefinition],
  setup() {
    const { sendClickEvent } = useGaEvent();
    return { sendClickEvent };
  },
  methods: {
    handleClick() {
      const isClickElementEventAvailable = !!this.clickElementElementId;
      if (!isClickElementEventAvailable) {
        return;
      }
      this.sendClickEvent(
        this.clickElementElementId,
        this.clickElementElementIndex,
      );
    },
  },
};
</script>

<style scoped>
.TitleLink {
  color: #333;
  font-size: 16px;
  text-decoration: none;
}

.TitleLink:hover {
  opacity: 0.7;
}

.arrow {
  fill: #333;
  height: 12px;
  width: 12px;
}
</style>
